import React, { useEffect, useReducer, useState } from 'react';
import { SectionHeader } from '../../modules/common/components/section-header';
import { Seo } from '../../modules/common/components/seo';
import { DashboardLayout } from '../../modules/layout/components/dashboard-layout';

import './index.scss';
import './characters.scss';
import { StaticImage } from 'gatsby-plugin-image';
import { Link } from 'gatsby';
import { Col, Row, Table } from 'react-bootstrap';
import { faSortAlphaUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WWStatsJSON } from '../../modules/ww/data/ww-stats-data';
import { WWCharacter } from '../../modules/ww/common/components/ww-character';

const WWCharStats: React.FC = () => {
  const [sortOption, setSortOption] = useState('name');
  const [listChars, setListChars] = useState(WWStatsJSON);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [ignored, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    if (sortOption === 'name') {
      setListChars(listChars.sort((a, b) => (a.slug > b.slug ? 1 : -1)));
    } else if (sortOption === 'base_atk') {
      setListChars(
        listChars.sort((a, b) => (a.base_atk < b.base_atk ? 1 : -1))
      );
    } else if (sortOption === 'base_hp') {
      setListChars(listChars.sort((a, b) => (a.base_hp < b.base_hp ? 1 : -1)));
    } else if (sortOption === 'base_def') {
      setListChars(
        listChars.sort((a, b) => (a.base_def < b.base_def ? 1 : -1))
      );
    } else if (sortOption === 'max_energy') {
      setListChars(
        listChars.sort((a, b) => (a.max_energy < b.max_energy ? 1 : -1))
      );
    }
    forceUpdate();
  }, [sortOption]);
  return (
    <DashboardLayout className={'generic-page characters-ww'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>Characters List</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../images/ww/categories/category_charstats.jpg"
            alt="Characters"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Characters Stats</h1>
          <h2>Compare the stats of all Wuthering Waves characters.</h2>
          <p>
            Last updated: <strong>22/07/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297229"></div>
      <SectionHeader title="Characters Stats" subtitle="At level 90" />
      <div className="character-stats-ww">
        <Row>
          <Col>
            <Table className="stats-table" striped bordered responsive>
              <thead>
                <tr>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'name' && 'current'}`}
                    onClick={() => setSortOption('name')}
                  >
                    NAME
                    {sortOption && sortOption === 'name' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'base_hp' && 'current'}`}
                    onClick={() => setSortOption('base_hp')}
                  >
                    HP
                    {sortOption && sortOption === 'base_hp' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'base_atk' && 'current'}`}
                    onClick={() => setSortOption('base_atk')}
                  >
                    ATK
                    {sortOption && sortOption === 'base_atk' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${sortOption === 'base_def' && 'current'}`}
                    onClick={() => setSortOption('base_def')}
                  >
                    DEF
                    {sortOption && sortOption === 'base_def' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                  <th
                    scope="col"
                    className={`stat ${
                      sortOption === 'max_energy' && 'current'
                    }`}
                    onClick={() => setSortOption('max_energy')}
                  >
                    Max Energy
                    {sortOption && sortOption === 'max_energy' && (
                      <span className="icon">
                        <FontAwesomeIcon icon={faSortAlphaUp} width="18" />
                      </span>
                    )}
                  </th>
                </tr>
              </thead>
              <tbody>
                {listChars.map((char, index) => {
                  return (
                    <tr key={index}>
                      <th scope="row" className="char">
                        <WWCharacter
                          slug={char.slug}
                          mode="icon"
                          enablePopover
                        />
                        <span className="name">{char.name}</span>
                      </th>
                      <td className="stat">
                        {(char.base_hp * 12.5).toFixed(0)}
                      </td>
                      <td className="stat">
                        {(char.base_atk * 12.5).toFixed(0)}
                      </td>
                      <td className="stat">
                        {(char.base_def * 12.22).toFixed(0)}
                      </td>
                      <td className="stat">{char.max_energy}</td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </Col>
        </Row>
      </div>
    </DashboardLayout>
  );
};

export default WWCharStats;

export const Head: React.FC = () => (
  <Seo
    title="Characters Stats | Wuthering Waves | Prydwen Institute"
    description="Compare the stats of all Wuthering Waves characters."
    game="ww"
  />
);
